<template>
  <NuxtLink type="button" :href="href">
    <div v-if="tag !== ''" class="text-condensed-bold px-2 py-[2px] rounded-2xl tag hover:scale-110 hover:mx-1">
      #{{ tag }}
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  tag: { type: String, required: true },
});

const href = computed(() => `/blog?${new URLSearchParams({ tags: props.tag })}`);
</script>

<style scoped lang="scss">
.tag {
  color: var(--dp-blog-tags-fg-color);
  background-color: var(--dp-blog-tags-bg-color);
  border: var(--dp-blog-tag-border-color);
  font-weight: var(--dp-blog-tag-font-weight) !important;
}
</style>
