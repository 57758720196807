import { ref, type Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useOrgId from '@/utils/useOrgId';
import { getBlogPaths } from '@/utils/storyblok/blog';
import { useI18n } from 'vue-i18n';
import { getStories } from '@/api';
import resolve_relations from '@/storyblok/resolve_relations';
import type { ISbStoryData } from '@storyblok/vue';
import { isEqual } from 'lodash-es';

function getFilterQuery({
  searchText,
  authorUuid,
  orgId,
  categories,
  tags,
}: {
  searchText: Ref<string>;
  authorUuid?: string;
  orgId?: string;
  categories?: string[];
  tags?: string[];
}) {
  return {
    filter_query: {
      // filter out hidden articles
      ...(orgId
        ? {
            visibility: { in: ['all', orgId].join(',') },
          }
        : {}),
      ...(searchText && searchText.value
        ? {
            __or: [
              { title: { like: `*${searchText.value}*` } },
              { description: { like: `*${searchText.value}*` } },
              { content: { like: `${searchText.value}` } },
            ],
          }
        : {}),
      ...(authorUuid
        ? {
            author: { like: authorUuid },
          }
        : {}),
      ...(categories
        ? {
            categories: { any_in_array: categories.join(',') },
          }
        : {}),
      ...(tags
        ? {
            tags: { any_in_array: tags.join(',') },
          }
        : {}),
    },
  };
}

async function getTagsUUIDs(tags: string[]): Promise<string[]> {
  const {
    data: { stories },
  } = await getStories<ISbStoryData>({
    by_slugs: tags.map(tag => `*/${tag}`).join(','),
  });
  return stories.map(({ uuid }) => uuid);
}

export async function useArticlesApi({
  pageSize: per_page = 9,
  authorUuid,
  excludeIds,
  categories,
}: {
  pageSize?: number;
  authorUuid?: string;
  excludeIds?: string[];
  categories?: string[];
}) {
  const route = useRoute();
  const router = useRouter();
  const i18n = useI18n();

  // route query utils
  const getQueryParam = (name: string): string | undefined => route && route.query && (route.query[name] as string);
  const getPageFromQuery = (): number => parseInt(getQueryParam('page') ?? '1', 10);
  const getSearchTextFromQuery = (): string => getQueryParam('search') ?? '';
  const getTagsFromQuery = (): string[] => {
    const tags = getQueryParam('tags');
    return tags ? tags.split(',') : [];
  };
  const updateQueryParamIfChanged = <T>(field: Ref<T>, getter: () => T) => {
    const newValue = getter();
    if (!isEqual(field.value, newValue)) field.value = newValue;
  };

  // refs
  const tags = ref(getTagsFromQuery());
  const page = ref(getPageFromQuery());
  const searchText = ref(getSearchTextFromQuery());
  const pending = ref(false);
  const orgId = useOrgId();
  const currentRoutePath = route.path;
  const getArticles = async () =>
    getStories({
      by_slugs: getBlogPaths(orgId.value, i18n.locale.value, 'blog/articles', '*'),
      per_page,
      page: page.value,
      sort_by: 'first_published_at:desc',
      resolve_relations,
      ...(excludeIds ? { excluding_ids: excludeIds.join(',') } : {}),
      ...getFilterQuery({
        searchText,
        authorUuid,
        categories,
        orgId: orgId.value,
        ...(tags.value.length > 0 ? { tags: await getTagsUUIDs(tags.value) } : {}),
      }),
    });

  const articles = ref();
  const pages = ref();

  const updateArticles = async () => {
    const {
      data: { stories },
      total,
    } = await getArticles();
    articles.value = stories;
    pages.value = Math.ceil(total / per_page);
  };

  watch(
    () => route && route.query,
    () => {
      updateQueryParamIfChanged(tags, getTagsFromQuery);
      updateQueryParamIfChanged(page, getPageFromQuery);
      updateQueryParamIfChanged(searchText, getSearchTextFromQuery);
    },
    { immediate: true }
  );

  watch(
    () => [page.value, searchText.value, tags.value],
    async () => {
      if (currentRoutePath === route.path) {
        await router.replace({
          query: {
            ...(page.value > 1 ? { page: page.value } : {}),
            ...(searchText.value ? { search: searchText.value } : {}),
            ...(tags.value.length > 0 ? { tags: tags.value.join(',') } : {}),
          },
          hash: route.hash,
        });

        pending.value = true;
        const updatedPromise = updateArticles();
        // this avoids the content flashing too fast if the response time is low
        const minimumTimeLag = new Promise(resolve => {
          setTimeout(resolve, 500);
        });
        await Promise.all([updatedPromise, minimumTimeLag]);
        pending.value = false;
      }
    }
  );

  await updateArticles();

  return { articles, page, searchText, pending, pages };
}
