<template>
  <BlogTagElement v-for="tag in resolvedTags" :key="tag.uuid" :tag="tag.content.label" />
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';

import type { StoryblokStory } from 'storyblok-generate-ts';
import type { BlogTag } from '@/types/storyblok';
import { getResolvedArrayField } from '@/utils/storyblok/resolved';

import BlogTagElement from '@/components/blog/BlogTagElement.vue';

const props = defineProps({
  tags: { type: Object as PropType<(string | StoryblokStory<BlogTag>)[]>, required: true },
});

const resolvedTags = computed(() => getResolvedArrayField(props.tags ?? []));
</script>
