import { DefaultLocale, VonageOrgID } from '@/constants';

export function getBlogPaths(orgId: string, lang: string, basePath: string, storyPath: string) {
  const orgs = [...new Set([orgId, VonageOrgID])];
  const langs = [...new Set([lang, DefaultLocale])];

  return orgs
    .reduce((list, id) => [...list, ...langs.map(l => `branding/${id}/${basePath}/${l}/${storyPath}`)], [] as string[])
    .join(',');
}
