import moment from 'moment/min/moment-with-locales';

const LocalMap: Record<string, string> = {
  cn: 'zh-CN',
};

export function setDateFormatLocale(locale: string) {
  moment.locale(LocalMap[locale] ?? locale);
}

export function dateFromString(timestamp: string): Date | null {
  const date = new Date(timestamp);
  return !Number.isNaN(date.valueOf()) ? date : null;
}

export function formatDate(date: Date, format: string): string {
  return moment(date).format(format);
}

export function compareDatesEquality(timestampOne: string, timestampTwo: string) {
  const dateOne = new Date(timestampOne);
  const dateTwo = new Date(timestampTwo);
  return dateOne.toDateString() === dateTwo.toDateString();
}
