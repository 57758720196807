<template>
  <div class="card max-w-[352px] flex flex-col gap-[12px]" :class="{ 'animate-pulse': pending }">
    <div class="flex flex-col gap-[12px]">
      <NuxtLink :href="href" type="button" class="flex flex-col gap-[12px]">
        <div class="w-full h-[166px] relative">
          <img
            :src="
              useStoryblokImageService(
                article.content.header.filename,
                {
                  fitIn: true,
                },
                { filters: { quality: 100 } }
              )
            "
            class="h-full w-full bg-cover bg-center bg-no-repeat rounded-2xl"
            :alt="article.content.header.filename?.title"
          />
          <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-2xl" />
        </div>
        <div class="relative">
          <span class="text-condensed-light text-nowrap px-1 text-[#0d0d0d]">{{ lastUpdatedAt }}</span>
          <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-md" />
        </div>
      </NuxtLink>
      <div class="relative">
        <div class="flex flex-row flex-wrap gap-1">
          <BlogTags :tags="props.article.content.tags ?? []" />
        </div>
        <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-md" />
      </div>
      <NuxtLink :href="href" type="button" class="flex flex-col gap-[12px]">
        <div class="relative">
          <div class="title text-extended-bold text-wrap title-height text-[#0d0d0d] line-clamp-2">
            {{ article.content.title }}
          </div>
          <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-md" />
        </div>
        <div class="relative">
          <div class="text-light text-wrap h-[60px] line-clamp-3 text-[#0d0d0d]">{{ article.content.description }}</div>
          <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-md" />
        </div>
      </NuxtLink>
    </div>
    <NuxtLink v-if="author" :href="authorHref" type="button">
      <div class="flex flex-row gap-4">
        <div class="relative">
          <StoryblokImage class="rounded-full" :image="authorStory.content.image" :width="40" :height="40" grayscale />
          <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-full" />
        </div>
        <div class="flex flex-col">
          <div class="relative">
            <span class="text-[#0d0d0d] text-bold">{{ authorStory.content.name }}</span>
            <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-md" />
          </div>
          <div class="relative">
            <span class="text-[#0d0d0d] text-light">{{ authorStory.content.title }}</span>
            <div v-if="pending" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-200 rounded-md" />
          </div>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { BlogArticleStory } from '@/types/storyblok';
import { useStoryblokImageService } from '@/utils/storyblok/image';
import { dateFromString, formatDate } from '@/utils/date';
import BlogTags from '@/components/blog/BlogTags.vue';
import { useI18n } from 'vue-i18n';
import { getResolvedField } from '@/utils/storyblok/resolved';

const i18n = useI18n();

const props = defineProps({
  article: { type: Object as PropType<BlogArticleStory>, required: true },
  author: { type: Boolean, default: false },
  pending: { type: Boolean, default: false },
});

const lastUpdatedAt = computed(() => {
  const date = dateFromString(
    props.article.first_published_at || props.article.published_at || props.article.created_at
  );
  return date && formatDate(date, 'LL');
});

const href = computed(() => `/${i18n.locale.value}/blog/${props.article.slug}`);

const authorStory = computed(() => getResolvedField(props.article.content.author));
const authorHref = computed(() => `/${i18n.locale.value}/blog/authors/${authorStory.value.slug}`);
</script>

<style scoped lang="scss">
.card:hover {
  .title {
    color: var(--vvd-color-cta-700);
  }
}
.title-height {
  height: var(--dp-blog-title-height);
}
</style>
